<template>
  <div class="legal">
    <Header />
    <Policy />
    <TitleRefugeesMenuTemplate
      style="background: #8d26d6"
      :title="title.titlePage"
      :subtitle="title.subtitlePage"
      :img="title.img"
    />
    <InformationsTemplate :card="acnur" />
    <InformationsTemplate :card="caritas" />
    <InformationsTemplate :card="cepremi" />
    <InformationsTemplate :card="conare" />
    <InformationsTemplate :card="coordenacao" />
    <InformationsTemplate :card="cras" />
    <InformationsTemplate :card="defensoria" />
    <InformationsTemplate :card="migrante" />
    <InformationsTemplate :card="refugiados" />
    <InformationsTemplate :card="rui" />
    <p>
      <router-link :to="{ name: 'refugees-menu-PT' }">{{$t("legal.linkName")}}</router-link>
    </p>
    <Contact />
    <Feedback />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header";
import TitleRefugeesMenuTemplate from "@/components/templates/TitleRefugeesMenuTemplate";
import InformationsTemplate from "@/components/templates/InformationsTemplate";
import Contact from "@/components/Contact";
import Feedback from "@/components/Feedback";
import Footer from "@/components/Footer";
import Policy from "@/components/Policy";

export default {
  components: {
    Header,
    TitleRefugeesMenuTemplate,
    InformationsTemplate,
    Contact,
    Feedback,
    Footer,
    Policy,
  },
  data() {
    return {
      title: {
        titlePage: this.$t("legal.titlePage"),
        subtitlePage: this.$t("legal.subtitlePage"),
        img: "legal.png",
      },
      acnur: {
        title: this.$t("legal.acnurTitle"),
        link: "https://www.acnur.org",
        linkName: this.$t("legal.acnurLinkName"),
      },
      caritas: {
        title: this.$t("legal.caritasTitle"),
        link: "http://www.caritas-rj.org.br/",
        linkName: this.$t("legal.caritasLinkName"),
      },
      cepremi: {
        title: this.$t("legal.cepremiTitle"),
        text: this.$t("legal.cepremiText"),
        link: "https://m.facebook.com/cepremi/about",
        linkName: this.$t("legal.crasLinkName"),
      },
      conare: {
        title: this.$t("legal.conareTitle"),
        link: "https://justica.gov.br/seus-direitos/refugio/conare",
        linkName: this.$t("legal.conareLinkName"),
      },
      coordenacao: {
        title: this.$t("legal.coordenacaoTitle"),
        link: "https://portaldeimigracao.mj.gov.br/pt/informacoes-gerais",
        linkName: this.$t("legal.coordenacaoLinkName"),
      },
      migrante: {
        title: this.$t("legal.migranteTitle"),
        link: "https://portaldeimigracao.mj.gov.br/pt/migranteweb",
        linkName: this.$t("legal.migranteLinkName"),
      },
      refugiados: {
        title: this.$t("legal.refugiadosTitle"),
        text: this.$t("legal.refugiadosText"),
        link: "https://m.facebook.com/pages/category/Social-Service/refugiados.asvp/posts/",
        linkName: this.$t("legal.crasLinkName"),
      },
      rui: {
        title: this.$t("legal.ruiTitle"),
        link: "https://www.google.com.br/maps/place/Funda%C3%A7%C3%A3o+Casa+de+Rui+Barbosa/@-22.9488011,-43.1892148,17z/data=!3m1!4b1!4m5!3m4!1s0x997fef0bd809f3:0x33f18623ff3f8e70!8m2!3d-22.9488011!4d-43.1870261",
        linkName: this.$t("legal.ruiLinkName"),
      },
      defensoria: {
        title: this.$t("shelters.defensoriaTitle"),
        text: this.$t("shelters.defensoriaText"),
        link:
          "https://www.google.com.br/maps/place/Defensoria+P%C3%BAblica+da+Uni%C3%A3o+no+Rio+de+Janeiro+(DPU%2FRJ)/@-22.9023347,-43.1832913,17z/data=!3m2!4b1!5s0x997f5ef27175b9:0x147b2aabe31da4f9!4m5!3m4!1s0x997f5ea21990c9:0xaa63fd16b0a8d8c4!8m2!3d-22.9023397!4d-43.1811026",
        linkName: this.$t("shelters.linkName2"),
      },
      cras :{
        title: this.$t("legal.crasTitle"),
        text: this.$t("legal.crasText"),
        link: "https://carioca.rio/servicos/cras-centro-de-referencia-de-assistencia-social/",
        linkName: this.$t("legal.crasLinkName")
      }
    };
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
p {
  margin: 0 0 50px 120px;
}
</style>